.bodyContainer {
  display: flex;
  //   align-items: center;
  justify-content: center;
  flex-direction: column;
  .inputContainer {
    display: flex;
    width: 100%;
    // justify-content: center;
    margin: 0 auto;
    // align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      letter-spacing: 0.3px;

      color: #000000;
    }
    div {
      display: flex;
      gap: 1rem;
      input {
        width: 504.11px;
        height: 55px;
        left: 175px;
        top: 209.25px;
        border: none;
        outline: none;
        padding-left: 2rem;
        background: #f4f4f4;
        border-radius: 8.66947px;
      }
      button {
        background: #ea5b0c;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 30px */
        color: #ffffff;
        border: none;
        outline: none;
        width: 150px;
        height: 52px;
      }
    }
  }
}
.btns {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: flex-end;
  button {
    cursor: pointer;
    width: 158px;
    height: 44px;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    &:nth-child(1) {
      background: #ffffff;
      border: 1px solid #ea5b0c;

      color: #ea5b0c;
    }
    &:nth-child(2) {
      background: #ea5b0c;

      color: #ffffff;
    }
  }
}
