.categoryAdd-container {
  display: flex;
  .categoryAdd-right {
    .categoryAdd-Bottom {
      display: flex;
      justify-content: space-between;
      height: 80%;
      flex-direction: column;
      background: #ffffff;
      /* grayscale / divider */
      margin: 1rem auto;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      width: 90%;
      padding: 2rem;
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #000000;
      }
      .inputCategory {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */

          letter-spacing: 0.3px;

          color: #000000;
        }
        input {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          letter-spacing: 0.3px;
          color: #a09b93;
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          border: none;
          outline: none;
          padding-left: 1rem;
        }
      }
      .file {
        margin-top: 1rem;
        input {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          letter-spacing: 0.3px;
          color: #a09b93;
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          border: none;
          outline: none;
          padding: 1.5rem 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .categorySelect {
        select {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          letter-spacing: 0.3px;
          color: #a09b93;
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          border: none;
          outline: none;
          padding: 1rem;
        }
        option {
          color: black;
          padding-top: 2rem;
        }
      }
    }
  }
}
