.info-container {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  .titles {
    display: flex;
    align-items: center;
    gap: 6rem;
  }
  .role {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: center;
    .left {
      gap: 6rem;
      display: flex;
    }
    .right {
      display: flex;
      gap: 1rem;
      button {
        width: 108px;
        height: 37px;
        left: 879px;
        top: 26px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        /* or 18px */
        cursor: pointer;
        color: #ffffff;
        &:nth-child(1) {
          background: #4fcf3b;
        }
        &:nth-child(2) {
          background: #e30000;
        }
      }
    }
  }
}
