.contactWrapper {
  display: flex;
  .contactBody {
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    width: 90%;
    margin: 1rem auto;
    padding: 1rem;
    .contactHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.3px;
        /* grayscale / black */
        color: #252733;
      }
      .contactLeft {
        width: 338.39px;
        height: 45.35px;
        background: #f4f4f4;
        border-radius: 8.66947px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          border-radius: 8.66947px;
          background: transparent;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;

          color: #aeabd8;
        }
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
