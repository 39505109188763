.locationContainer {
  display: flex;
  .locationWrapper {
    .location {
      background: #ffffff;
      /* grayscale / divider */

      border: 1px solid #dfe0eb;
      border-radius: 8px;
      width: 1000px;
      margin: 1rem auto;
      .locationBody {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        h1 {
          font-weight: 700;
          font-size: 19px;
          line-height: 24px;
          /* identical to box height */

          letter-spacing: 0.4px;

          /* grayscale / black */

          color: #252733;
        }
        .aboutLeftContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .aboutInput {
            width: 338.39px;
            height: 45.35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f4f4;
            border-radius: 8.66947px;

            input {
              background: #f4f4f4;
              border-radius: 8.66947px;
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              padding-left: 1rem;
            }
            svg {
              font-size: 20px;
            }
          }
          button {
            width: 150px;
            height: 52px;
            background: #ea5b0c;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: #ffffff;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
