.settings-wrapper {
  display: flex;
  .body {
    margin: auto;
    width: 95%;
    margin-top: 2rem;
    padding: 0 2rem;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
  }
}
