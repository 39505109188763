.updateProductContainer {
  display: flex;
}
.productWrapper {
  background: #ffffff;
  /* grayscale / divider */

  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: 90%;
  margin: 1rem auto;
}
