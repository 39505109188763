.speWrapper{
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
    top: 20%;
    background: #cfcece;
    border: #ea5b0c 1px solid;
    flex-direction: column;
    
    gap: 1rem;
    // margin: auto;
    .closeBtn{
        align-self: flex-end;
        cursor: pointer;
        font-size: 1.5rem;
        color: black;
        margin: 0 1rem;
    }
    .speSelect{
        width: 20rem;
        height: 2rem;
        outline: none;
        border: none;
    }
    .speInput{
        width: 20rem;
        // height: 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        span{
            color: #ea5b0c;
            font-size: 20px;
        }
        input{
            width: 20rem;
            height: 2rem;
        }
    }
    .speButton{
        background: #ea5b0c ;
        width: 10rem;border: none;outline: none;
        color: white;
        height: 3rem;
        border-radius: 20px;
    }
}
.speWrapperHidden{
    display: hidden;
}