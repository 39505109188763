.info-wrapper {
  display: flex;
  .infoTop {
    display: flex;

    flex-direction: column;
    .settingHeader {
      display: flex;
      gap: 8rem;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #000000;
    }
    .settingBottom {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1rem;
      div {
        display: flex;
        gap: 8rem;
        font-weight: 400;
        font-size: 13px;
        line-height: 110%;
        /* or 14px */
        color: #000000;
      }
      button {
        background: #e30000;
        /* Shadow 2 */
        border: none;
        outline: none;
        padding: 1rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        /* or 18px */
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
}
