.emailContainer {
  display: flex;
  .emailWrapper {
    .emailBodyContainer {
      background: #ffffff;
      width: 90%;
      margin: 1rem auto;
      padding: 1rem;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .inputContainer {
        align-self: flex-end;
        width: 338.39px;
        height: 45.35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f4f4f4;
        border-radius: 8.66947px;

        input {
          background: #f4f4f4;
          border-radius: 8.66947px;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding-left: 1rem;
        }
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
