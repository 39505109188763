.content-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding-left: 2rem;
  border-bottom: 1px solid #f4f4f4;
  margin-top: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      color: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
      padding: 0.6rem;
      border-radius: 6px;
      cursor: pointer;
      &:nth-child(1) {
        background: #4fcf3b;
      }
      &:nth-child(2) {
        background: #e30000;
      }
    }
  }
}
.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.infoContent {
  max-width: 80%;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.item3 {
  display: flex;
  width: 50vh;
  justify-content: space-between;
  align-items: center;
  // flex: 1;
}
.item4 {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  width: 50vh;
  justify-content: space-between;
  // flex: 2;
}
