.EmailBodyContainer {
  width: 90%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* grayscale / divider */
  padding: 2rem;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  .emailSearch {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .emailTitles {
    display: flex;
    gap: 5rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-top: 1rem;
  }
  .emailTable {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    gap: 2rem;

    .tableRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rowLeft {
        display: flex;
        gap: 5rem;
      }
      button {
        width: 108px;
        height: 37px;
        border: none;
        outline: none;
        background: #e30000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
