.input {
  background: #f4f4f4;
  border-radius: 8.66947px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 1rem;

  svg {
    width: 24.09px;
    color: #aeabd8;
    height: 31.04px;
  }
  input {
    background: transparent;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #aeabd8;
    height: 45.35px;
    width: 338.39px;
  }
}
