.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  .bar {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .first {
      position: absolute;
      top: 20px;
      left: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .cir {
        background-color: #ea5b0c;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: white;
          font-size: 18px;
        }
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #444444;
      }
    }
    .second {
      position: absolute;
      top: 20px;
      left: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .cir {
        background-color: #ea5b0c;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: white;
          font-size: 18px;
        }
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #444444;
      }
    }
    .third {
      position: absolute;
      top: 20px;
      left: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .cir {
        background-color: #ea5b0c;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: white;
          font-size: 18px;
        }
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #444444;
      }
    }
  }
}
.cir2 {
  background-color: #bfbfbf;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: white;
    font-size: 18px;
  }
}
