.AddProductForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
  }
  .FormName {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    input {
      background: #f4f4f4;
      border-radius: 8.66947px;
      width: 501px;
      height: 55px;
      border: none;
      outline: none;
      padding-left: 1rem;
    }
  }
  .selectForm {
    select {
      display: flex;
      background: #f4f4f4;
      border-radius: 8.66947px;
      width: 501px;
      border: none;
      outline: none;
      padding-left: 1rem;
      height: 55px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      letter-spacing: 0.3px;
      color: #000000;

      option {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        padding: 1rem 0rem;
        letter-spacing: 0.3px;
        color: #000000;
      }
    }
  }
  .ProductCheck {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    div {
      display: flex;
      gap: 1rem;
      input {
        accent-color: #ea5b0c;
      }
    }
  }
  .AddProductBtn {
    display: flex;
    gap: 1rem;
    align-self: self-end;
    button {
      width: 158px;
      height: 44px;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.3px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      border: none;
      outline: none;
      &:nth-child(2) {
        color: #ffffff;
        background: #ea5b0c;
      }
      &:nth-child(1) {
        color: #ea5b0c;
        background: #ffffff;
        border: 1px solid #ea5b0c;
      }
    }
  }
}
