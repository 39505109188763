.header-container {
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0rem 1rem;
  .title {
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: #252733;
    }
  }
  .detail-container {
    display: flex;
    gap: 1rem;
    .mail {
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        color: #000000;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #71839b;
      }
    }
    .logoutButton {
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow-bg {
        width: 15px;
        height: 15px;
        background-color: #ea5b0c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: white;
        }
      }
      button {
        text-decoration: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.3px;
        background: none;
        cursor: pointer;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border: none;
        outline: none;
      }
    }
  }
}
@media screen and (max-width: 795px) {
  .header-container {
    flex-direction: column;
  }
}
