.aboutContainer {
  display: flex;

  .wrapper {
    .aboutContainer {
      background: #ffffff;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      width: 90%;
      margin: 1rem auto;
      flex-direction: column;

      .aboutBody {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;

        h1 {
          font-weight: 700;
          font-size: 19px;
          line-height: 24px;
          /* identical to box height */

          letter-spacing: 0.4px;

          /* grayscale / black */

          color: #252733;
        }

        .aboutLeftContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .aboutInput {
            width: 338.39px;
            height: 45.35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f4f4;
            border-radius: 8.66947px;

            input {
              background: #f4f4f4;
              border-radius: 8.66947px;
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              padding-left: 1rem;
            }

            svg {
              font-size: 20px;
            }
          }

          button {
            width: 150px;
            height: 52px;
            background: #ea5b0c;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: #ffffff;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }

      .head {
        display: flex;
        gap: 9rem;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr.1fr.1fr.1fr.1fr.1fr.1fr;
        border-bottom: 1px solid #f4f4f4;
      }

      .body {
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr.1fr.1fr.1fr.1fr.1fr;
        gap: 6rem;

        p {
          &:nth-child(2) {
            // width: 80%;
            height: 90%;
          }

          font-weight: 400;
          font-size: 13px;
          line-height: 110%;
          color: #000000;
        }

        .aboutItem {
          img {
            width: 105px;
            height: 97px;
            object-fit: contain;
          }

        }
        .aboutButtons{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          flex-direction: row;
          button {
            width: 60px;
            height: 40px;
            :nth-child(1) {
              background: #4fcf3b;
            }
            :nth-child(2) {
              background: #e30000;
            }
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            border: none;
            outline: none;
            cursor: pointer;
            font-weight: 400;
            font-size: 12px;
            line-height: 110%;
            color: #ffffff;
          }
        }
      }
    }
  }
}
