.role-container {
  display: flex;
  .mid {
    /* grayscale / white */
    background: #ffffff;
    /* grayscale / divider */
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    width: 90%;
    height: fit-content;
    margin-top: 1rem;
    margin-left: 2rem;
  }
}
