.tableHeader-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .input {
      background: #f4f4f4;
      border-radius: 8.66947px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem 0 1rem;

      svg {
        width: 24.09px;
        color: #aeabd8;
        height: 31.04px;
      }
      input {
        background: transparent;
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #aeabd8;
        height: 45.35px;
        width: 338.39px;
      }
    }
    button {
      background: #ea5b0c;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-weight: 600;
      width: 120px;
      height: 50px;
      font-size: 18px;
      line-height: 150%;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
.header-name {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  gap: 2rem;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #000000;
  }
}
.header-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item1 {
  display: flex;
  gap: 3rem;
  align-items: center;
  // flex: 1;
}
.item2 {
  margin-left: 3rem;
  display: flex;
  align-items: center;
  gap: 3rem;
  // flex: 2;
}
