.warning {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 40%;
  left: 30%;
  z-index: 10000;
  width: 588px;
  height: 190px;
  .war-header {
    padding: 1rem;
    background-color: #e30000;
    height: 55px;
    display: flex;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.250794px;

      color: #ffffff;
    }
  }
  .bottom {
    height: calc(190px - 55px);
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // margin-top: 1rem;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.250794px;
      color: #000000;
    }
    .btns {
      display: flex;
      align-self: flex-end;
      gap: 1rem;
      button:nth-child(1) {
        background: #4fcf3b;
        border-radius: 6px;
        width: 122px;
        height: 38px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #ffffff;
        border: none;
        outline: none;
        cursor: pointer;
      }
      button:nth-child(2) {
        background: #e30000;
        border-radius: 6px;
        width: 122px;
        height: 38px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #ffffff;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
