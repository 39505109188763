.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  .login-wrapper {
    background: #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 524px;
    height: 617px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    img {
      width: 342px;
      height: 92px;
    }
    h1 {
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;
      color: #242331;
    }
    .form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      .username-container,
      .password-container {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #2b2b2b;
        }
        input {
          width: 380px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #d7d7d7;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: rgba(43, 43, 43, 0.5);
          padding: 1rem;
          outline-color: #ea5b0c;
        }
      }
      .check-box {
        display: flex;
        gap: 0.5rem;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
        }
        input {
          background: #ffffff;
          border: 2px solid #e4e4e4;
          border-radius: 2px;
          accent-color: #ea5b0c;
        }
      }
    }
    button {
      width: 380px;
      height: 48px;
      background: #ea5b0c;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      border: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
      margin-top: 4rem;
      cursor: pointer;
    }
  }
}
.error {
  color: red;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .login-container {
    margin-top: 5rem;
    .login-wrapper {
      padding: 1rem;
      width: 324px;
      height: 417px;
      gap: 1rem;
      img {
        width: 300px;
        height: 92px;
      }
      h1 {
        font-size: 38px;
      }
      .form {
        .username-container,
        .password-container {
          span {
            font-size: 13px;
          }
          input {
            width: 280px;
            height: 38px;
            font-size: 10px;
          }
        }
        .check-box {
          display: flex;
          gap: 0.5rem;
          span {
            font-size: 12px;
          }
        }
      }
      button {
        width: 280px;
        height: 48px;

        margin-top: 1rem;
      }
    }
  }
}
