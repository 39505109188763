.sidebarContainer {
  width: 288px;
  height: 100vh;
  background: #ffffff;
  border: 0.859788px solid #dfe0eb;
  display: flex;
  align-items: center;
  gap: 4.5rem;
  flex-direction: column;
  .imgContainer {
    padding-top: 1rem;
    width: 164px;
    height: 44px;
    object-fit: contain;
  }
  .firstSide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .header {
      display: flex;
      width: 248px;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        svg {
          cursor: auto;
        }
      }
      align-items: center;
      gap: 1rem;
      background-color: #ea5b0c;
      padding: 0.5rem;
      border-radius: 10px;
      svg {
        color: #fff;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
    .link-container {
      height: 116px;
      border-left: 1px solid #a0a0a0;
      padding: 0.5rem 0.3rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .navLink {
        padding-left: 1rem;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-decoration: none;
        color: #6d6d6d;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 0.5rem;
          left: -0.37rem;
          top: 50%;
          background: #6d6d6d;
          border: 0.5px solid #9b9390;
          border-radius: 6px;
        }
      }
    }
  }
  .SecondSide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .header {
      display: flex;
      width: 248px;
      align-items: center;
      gap: 1rem;
      background-color: #ea5b0c;
      padding: 0.5rem;
      border-radius: 10px;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        svg {
          cursor: auto;
        }
      }
      svg {
        color: #fff;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
    .link-container {
      height: 310px;
      border-left: 1px solid #a0a0a0;
      padding: 0.5rem 0.3rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .navLink {
        padding-left: 1rem;
        font-weight: 600;
        text-decoration: none;
        font-size: 16px;
        line-height: 150%;
        color: #6d6d6d;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 0.5rem;
          left: -0.37rem;
          top: 50%;
          background: #6d6d6d;
          border: 0.5px solid #9b9390;
          border-radius: 6px;
        }
      }
    }
  }
}
.navLinkActive {
  padding-left: 1rem;
  font-weight: 600;
  text-decoration: none;
  font-size: 16px;
  line-height: 150%;
  color: #ea5b0c;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 0.5rem;
    left: -0.37rem;
    top: 50%;
    background: #6d6d6d;
    border: 0.5px solid #9b9390;
    border-radius: 6px;
  }
}
.hidden {
  display: none !important;
}

.hidden2 {
  display: none !important;
}
@media screen and (max-width: 795px) {
  .sidebarContainer {
    width: 200px;
    height: 100vh;
    .imgContainer {
      img {
        width: 135px;
        height: 44px;
      }
    }
    .firstSide {
      .header {
        width: 180px;
        p {
          font-size: 10px;
        }
      }
      .link-container {
        height: 97px;
        p {
          font-size: 12px;
        }
      }
    }
    .SecondSide {
      .header {
        width: 180px;
        p {
          font-size: 10px;
        }
      }
      .link-container {
        height: 200px;

        p {
          font-size: 12px;
        }
      }
    }
  }
}
