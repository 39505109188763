.productContainer {
  display: flex;
  .productWrapper {
    width: 95%;
    margin: 1rem auto;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
  }
}
