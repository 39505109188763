.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  background: #ffffff;
  /* grayscale / divider */
  margin-top: 2rem;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #000000;
    }
    input {
      width: 404px;
      height: 55px;
      background: #f4f4f4;
      border-radius: 8.66947px;
      border: none;
      outline: none;
      padding-left: 1rem;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #a09b93;
    }
  }
  .spanPer {
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    margin-top: 2rem;
    letter-spacing: 0.3px;
    color: #000000;
  }
  .per {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
    .check {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #000000;
      input {
        accent-color: #ea5b0c;
      }
    }
  }
  button {
    width: 158px;
    height: 44px;
    border: none;
    outline: none;
    background: #4fcf3b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */
    cursor: pointer;
    letter-spacing: 0.3px;
    margin-top: 3rem;
    color: #ffffff;
  }
}
