.update {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  /* or 18px */

  color: #ffffff;
  width: 108px;
  height: 37px;
  left: 861px;
  top: 31px;

  background: #4fcf3b;
  /* Shadow 2 */
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-right: 2rem;
  cursor: pointer;
}

.delete {
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  /* or 18px */
  border: none;
  outline: none;
  color: #ffffff;
  width: 108px;
  height: 37px;
  left: 861px;
  top: 31px;

  background: #e30000;
  /* Shadow 2 */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
