.add {
  display: flex;
  flex-direction: row;
}
.add-container {
  margin-top: 2rem;
  width: 80%;
  height: 85%;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 1rem;
  margin-left: 1rem;
  .top2 {
    display: flex;
    // justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    .item {
      gap: 0.5rem;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #000000;
      }
      input,
      select {
        width: 338px;
        height: 55px;
        background: #f4f4f4;
        border-radius: 8.66947px;
        border: none;
        outline: none;
        padding-left: 1rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        letter-spacing: 0.3px;
        color: #a09b93;
      }
      option {
        color: black;
      }
    }
    .middle {
      display: flex;
      flex: row;
      display: flex;
      gap: 4rem;
      div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.3px;
          color: #000000;
        }
        input {
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          border: none;
          outline: none;
          padding-left: 1rem;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          letter-spacing: 0.3px;
          color: #a09b93;
        }
      }
    }
  }
  .bottom {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    .stats {
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
      }
      .select {
        display: flex;
        gap: 1rem;
        div {
          display: flex;
          gap: 1rem;
          input {
            accent-color: #ea5b0c;
          }
        }
      }
    }
    button {
      width: 158px;
      height: 44px;
      background: #4fcf3b;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      outline: none;
      border: none;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
