.itemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 2rem;
  border-bottom: 1px solid #dfe0eb;
  .item-wrapper {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    .itemLeft {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: 0.3px;

        color: #000000;
      }
    }
    .itemRight {
      img {
        width: 246.15px;
        object-fit: contain;
        height: 146.77px;
      }
    }
  }
}
.categoryBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 1rem;
  padding: 1rem;
  button {
    width: 108px;
    height: 37px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    /* or 18px */
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 2rem;
    color: #ffffff;
  }
  :nth-child(2) {
    background: #4fcf3b;
  }
  :nth-child(2) {
    background: #e30000;
  }
}
