.PartnerContainer {
  display: flex;
  .PartnerWrapper {
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    width: 90%;
    margin: 1rem auto;
    display: flex;
    .PartnerTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .buttonGroup {
        display: flex;
        width: 172px;
        height: 46px;
        // padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0px 0px 4px;

        border: 1px solid #dfe0eb;
        button {
          border: none;
          outline: none;
          width: 72px;
          height: 36px;
          cursor: pointer;
        }
        .orange {
          background: #ea5b0c;
          color: #ffffff;
          border-radius: 4px 0px 0px 4px;
        }
        .white {
          background: #ffffff;
          color: black;
          border-radius: 4px 0px 0px 4px;
        }
      }
      .partnerTopLeft {
        display: flex;
        align-items: center;
        gap: 1rem;
        .partnerSearch {
          width: 338.39px;
          height: 45.35px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2rem;
          input {
            border: none;
            outline: none;
            background: transparent;
            width: 100%;
            height: 100%;
          }
          svg {
            font-size: 1.5rem;
          }
        }
        button {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          /* identical to box height, or 30px */

          color: #ffffff;
          width: 150px;
          height: 52px;
          left: 970px;
          top: 23px;
          background: #ea5b0c;
          /* Shadow 2 */
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}
