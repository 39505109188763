.top {
  width: 100%;
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #252733;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 338.39px;
    height: 45.35px;
    background: #f4f4f4;
    border-radius: 8.66947px;
    padding: 0 2rem;
    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background: transparent;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #aeabd8;
    }
    svg {
      width: 24.09px;
      height: 31.04px;
      color: #aeabd8;
    }
  }
}
