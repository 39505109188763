.AddClientContainer {
  display: flex;
  .Wrapper {
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    width: 90%;
    padding: 1rem 2rem;
    margin: 1rem auto;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      /* identical to box height, or 83% */

      letter-spacing: 0.3px;

      color: #000000;
    }
    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .nameInput,
      .urlInput,
      .imgInput {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */

          letter-spacing: 0.3px;

          color: #000000;
        }
        input {
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          border: none;
          outline: none;
          padding-left: 1rem;
          letter-spacing: 0.3px;

          color: #a09b93;
        }
      }
      .imgInput {
        input {
          padding: 1rem 2rem;
        }
      }
    }
    .buttonSave {
      button {
        width: 158px;
        height: 44px;
        left: 30px;
        top: 737px;

        background: #4fcf3b;
        /* Shadow 2 */
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height, or 100% */

        letter-spacing: 0.3px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
  }
}
