.updateAbout {
  display: flex;
  .wrapper {
    .AboutWrapper {
      display: flex;
      flex-direction: column;
      /* grayscale / white */
      background: #ffffff;
      /* grayscale / divider */
      padding: 1rem;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      width: 90%;
      margin: 1rem auto;

      .AboutInput {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          /* identical to box height, or 100% */
          letter-spacing: 0.3px;
          color: #000000;
        }
        textarea {
          width: 573px;
          height: 153px;
          resize: none;
          background: #f1f1f1;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 1rem 2rem;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          /* identical to box height, or 133% */

          letter-spacing: 0.3px;

          color: #a09b93;
        }
        .inputAbout2 {
          display: flex;
          gap: 1rem;
          align-items: center;
          // justify-content: center;
          input {
            &:nth-child(1) {
              width: 573px;
              height: 76px;
              outline: none;
              border: none;
              background: #f1f1f1;
              border-radius: 8px;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              /* identical to box height, or 133% */

              letter-spacing: 0.3px;
              padding: 2rem;
              color: #a09b93;
            }
            &:nth-child(2) {
              width: 158px;
              height: 44px;
              left: 634px;
              top: 626px;

              background: #ea5b0c;
              /* Shadow 2 */

              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 6px;
            }
          }
        }
      }
      button {
        width: 158px;
        height: 44px;
        left: 30px;
        top: 737px;
        background: #4fcf3b;
        /* Shadow 2 */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        border: none;
        outline: none;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
}
