.newContainer {
  display: flex;
  .newBody {
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    width: 90%;
    margin: 1rem auto;
    .addNewForm {
      width: 100%;
      height: 90vh;
      display: flex;
      flex-direction: column;
      //   align-items: center;
      padding: 2rem;
      justify-content: space-between;
      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #000000;
      }
      .newInputFile {
        width: 338px;
        height: 55px;
        background: #f4f4f4;
        border-radius: 8.66947px;
        padding: 1rem 2rem;
      }
      .newInput {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */

          letter-spacing: 0.3px;

          color: #000000;
        }
        input {
          width: 338px;
          height: 55px;
          background: #f4f4f4;
          border-radius: 8.66947px;
          border: none;
          outline: none;
          padding: 1rem;
        }
        .contentNew {
          width: 338px;
          height: 152px;
        }
      }
      .newCheckBox {
        display: flex;
        gap: 1rem;
      }
      .buttonSave {
        width: 158px;
        height: 44px;
        left: 30px;
        top: 737px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height, or 100% */

        letter-spacing: 0.3px;
        border: none;
        outline: none;
        color: #ffffff;

        background: #4fcf3b;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
  }
}
