.table-container {
  height: fit-content;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
}
