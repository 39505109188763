.productTwoTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  thead {
    tr {
      display: flex;
      gap: 6rem;
      th {
        &:nth-child(2) {
          padding-right: 4rem;
        }
      }
    }
  }
  tbody {
    display: flex;
    flex-direction: column;
    tr {
      display: flex;
      align-items: center;
      gap: 5rem;
    }
    td {
      &:nth-child(2) {
        width: 100px;
      }
      img {
        width: 84px;
        height: 84px;
        object-fit: contain;
        border-radius: 8px;
      }
    }
  }
}

.button {
  cursor: pointer;
  border: none;
  outline: none;
  color: white;
  width: 108px;
  height: 37px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  background: #4fcf3b;
}
.button2 {
  cursor: pointer;
  border: none;
  outline: none;
  color: white;
  width: 108px;
  height: 37px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  background: #e30000;
}
.ProductTwoContainer {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  .addinput {
    display: flex;
    gap: 1rem;
    margin: 1rem;

    input {
      background: #f4f4f4;
      border-radius: 8.66947px;
      width: 501px;
      height: 73px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
    }
    button {
      width: 158px;
      height: 44px;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      background: #ea5b0c;

      color: #ffffff;
    }
  }
  .backandnext {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: flex-end;
    button {
      width: 158px;
      height: 44px;
      border: 1px solid #dfe0eb;
      border-radius: 8px;
      &:nth-child(1) {
        background: #ffffff;
        border: 1px solid #ea5b0c;

        color: #ea5b0c;
      }
      &:nth-child(2) {
        background: #ea5b0c;

        color: #ffffff;
      }
    }
  }
}
