.categoryHeaderContainer {
  display: flex;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  justify-content: space-between;
  margin: 2rem auto;
  flex-direction: column;
  width: 90%;
  .categoryTop {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    margin: 1rem 0 0 0;
    width: 100%;

    .categoryTop2 {
      display: flex;
      align-self: flex-end;
      gap: 1rem;

      .categoryInput {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8.66947px;
        background: #f4f4f4;

        input {
          background-color: transparent;
          border: none;
          outline: none;
          width: 338.39px;
          height: 45.35px;
          padding-left: 1rem;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #aeabd8;
        }
        svg {
          font-weight: 400;
          font-size: 20px;
          line-height: 14px;
          cursor: pointer;
          color: #aeabd8;
        }
      }
      button {
        width: 120px;
        height: 52px;
        background: #ea5b0c;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
  .categoryBottom2 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    gap: 1rem;
    padding: 1rem;
    button {
      width: 108px;
      height: 37px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      /* or 18px */
      outline: none;
      border: none;
      cursor: pointer;
      // margin-top: 2rem;
      color: #ffffff;
    }
    :nth-child(1) {
      background: #4fcf3b;
    }
    :nth-child(2) {
      background: #e30000;
    }
  }
}
