.locationBody {
  background: #ffffff;
  /* grayscale / divider */

  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: 90%;
  margin: 1rem auto;
  padding: 2rem;
  .locationInput {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      letter-spacing: 0.3px;

      color: #000000;
    }
    input {
      width: 338px;
      height: 55px;
      background: #f4f4f4;
      border-radius: 8.66947px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      border: none;
      outline: none;
      letter-spacing: 0.3px;
      padding-left: 1rem;
      color: #a09b93;
    }
  }
  .locationInputContainer {
    display: flex;
    gap: 3rem;
    margin-top: 2rem;
    .inputSmall {
      display: flex;
      flex-direction: column;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: 0.3px;

        color: #000000;
      }
      input {
        width: 192px;
        height: 55px;
        background: #f4f4f4;
        border-radius: 8.66947px;
        border: none;
        outline: none;
        padding-left: 1rem;
      }
    }
  }
  button {
    width: 158px;
    height: 44px;
    left: 30px;
    top: 737px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    letter-spacing: 0.3px;

    color: #ffffff;
    margin-top: 2rem;
    background: #4fcf3b;
    /* Shadow 2 */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
}
